<template>
  <div class="jh-container">
    <div class="jh-ui-header">
      <h1>OB관리 통계</h1>
      <div class="is-right">
      </div>
    </div>
    <!--조회-->
    <div class="jh-search-form">
      <table>
        <colgroup>
          <col width="55px">
          <col width="250px">
          <col width="70px">
          <col width="120px">
          <col>
        </colgroup>
        <tr>
          <th><label>등록일자</label></th>
          <td>
            <div class="jh-cols">
              <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                  maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                </template>
                <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
              </v-menu>
              <span class="jh-unit">~</span>                       
              <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                  maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                </template>
                <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
              </v-menu>
            </div>
          </td>
          <th><label>유형</label></th>
          <td>
            <v-select 
              class="jh-form" 
              :items="obTypeList"
              v-model="OBTYPE"
            >
            </v-select>
          </td>
          <td class="has-search"><v-btn class="jh-btn is-search" @click="searchList">조회</v-btn></td>
        </tr>
      </table>
    </div>
    <!--//조회-->

    <div class="jh-form-wrap">
      <div class="jh-ui-header">
        <h2>아웃바운드 리스트</h2>
        <div class="is-right"></div>
      </div>            
      <data-tables
        ref="dataTable"
        dataTableClass="has-control"
        :data-table-options="dataTableOptionsEvent"
        @click:row="onClickRowEvent"
        v-model="selectedRows"
      >
      </data-tables>
      
      <div class="jh-block jh-cols is-mt-10">
        <label class="jh-label">등록일자 :</label>
        <input type="text" class="jh-form is-md is-trns is-bold is-txt-blue is-p-0" style="max-width:90px" readonly v-model="REG_DTTM">
        <label class="jh-label">OB명 :</label>
        <input type="text" class="jh-form is-md is-trns is-bold is-txt-blue is-p-0" readonly v-model="OB_EVNT">
        <v-btn class="jh-btn" @click="excelDownload()">엑셀다운로드</v-btn>
      </div>

      <div class="jh-ui-header is-mt-10">
          <h3 class="has-bullet">통보결과</h3>
      </div>
      <data-tables
        ref="dataTableDetail"
        id="OBList05"
        dataTableClass=""
        :data-table-options="dataTableOptions01"
        v-model="selectedDetailRows01"
      >
        <template v-slot:header>
          <thead class="v-data-table-header">
            <tr>
              <th rowspan="2" width="100px"><span>등록일자</span></th>
              <th rowspan="2" width="100px"><span>유형</span></th>
              <th rowspan="2" width="300px"><span>OB명</span></th>
              <th rowspan="2" width="100px"><span>업로드건수</span></th>
              <th colspan="2"><span>통화시도</span></th>
              <th colspan="2"><span>통화성공</span></th>
              <th colspan="2"><span>무응답</span></th>
              <th colspan="2"><span>재통화</span></th>
              <th colspan="2"><span>통화거부</span></th>
              <th colspan="2"><span>결번</span></th>
              <th colspan="2"><span>통보제외</span></th>
              <th colspan="2"><span>기타</span></th>
            </tr>
            <tr>
              <th><span>1차</span></th>
              <th><span>N차</span></th>
              <th><span>1차</span></th>
              <th><span>N차</span></th>
              <th><span>1차</span></th>
              <th><span>N차</span></th>
              <th><span>1차</span></th>
              <th><span>N차</span></th>
              <th><span>1차</span></th>
              <th><span>N차</span></th>
              <th><span>1차</span></th>
              <th><span>N차</span></th>
              <th><span>1차</span></th>
              <th><span>N차</span></th>
              <th><span>1차</span></th>     
              <th><span>N차</span></th>
            </tr>
          </thead>
        </template>
      </data-tables>

      <div class="jh-ui-header is-mt-10">
        <h3 class="has-bullet">신청유무결과</h3>
      </div>
      <data-tables
        ref="dataTableDetail"
        id="OBList06"
        dataTableClass=""
        :data-table-options="dataTableOptions02"
        v-model="selectedDetailRows02"
        v-if="this.selectedOBTYPE == ''"
      >
      </data-tables>
    </div>
  </div>
</template>
  
<script>
import XLSX from "xlsx";
import moment from "moment";
import { mapGetters } from "vuex";
import api from "@/store/apiUtil.js";
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";

export default {
  name: "MENU_E010304", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],

  components: {
    dataTables
  },

  data() {
    return {
      menuStartDate: false,
      menuEndDate  : false,
      isLoading    : false,

      commonCodeItems      : [],
      selectedRows         : [],
      selectedDetailRows01 : [],
      selectedDetailRows02 : [],

      REG_DTTM        : "",
      OBTYPE          : "",
      OB_EVNT         : "",
      obTypeName      : "",
      selectedOBTYPE  : "",
      selectedEventId : "",

      from: moment().subtract(30, "days").format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      dates: [
        moment().subtract(30, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],

      userInfo: {
        USER_ID    : this.$store.getters["userStore/GE_USER_ROLE"].userId,
        USER_NM    : this.$store.getters["userStore/GE_USER_ROLE"].userName,
        USER_ATTR_A: this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_A,
        USER_ATTR_B: this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_B,
        USER_ATTR_C: this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_C,
      },
      
      // 마스터
      headersEvent: [
        { text: 'No', value: 'index', align: 'center', width: '60px', sortable:false},
        { text: 'OB명', value: 'OB_EVNT', },
        { text: '등록일', value: 'REG_DTTM', align: 'center', width: '200px', },
        { text: '등록자사번', value: 'REG_ID', align: 'center', width: '200px', },
        { text: '등록자', value: 'REG_NM', align: 'center', width: '200px', },
        { text: '업로드건수', value: 'UPLOAD_CNT', align: 'center', width: '200px', },
      ],

      itemsEvent: [],

      dataTableOptionsEvent: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '310px',
        items: undefined,
        itemKey: 'index',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        singleSelect: true,
        disablePagination: true,
      }, 

      // 통보결과
      headers: [
        { text: '등록일자',    value: 'REG_DTTM',         align: 'center', width: '100px', sortable:false},
        { text: '유형',        value: 'OB_TYPE_NM',       lign: 'center', width: '100px', sortable:false},
        { text: 'OB명',        value: 'OB_EVNT',          lign: 'center', sortable:false},
        { text: '업로드건수',   value: 'UPLOAD_CNT',      align: 'center', width: '90px', sortable:false},
        { text: '통화시도 1차', value: 'CALL_ATTMPT_1st', align: 'center', width: '70px', sortable:false},
        { text: '통화시도 N차', value: 'CALL_ATTMPT_Nth', align: 'center', width: '70px', sortable:false},
        { text: '통화성공 1차', value: 'NTFCTN_1st_01',   align: 'center', width: '70px', sortable:false},
        { text: '통화성공 N차', value: 'NTFCTN_Nth_01',   align: 'center', width: '70px', sortable:false},
        { text: '무응답 1차',   value: 'NTFCTN_1st_02',   align: 'center', width: '70px', sortable:false},
        { text: '무응답 N차',   value: 'NTFCTN_Nth_02',   align: 'center', width: '70px', sortable:false},
        { text: '재통화 1차',   value: 'NTFCTN_1st_03',   align: 'center', width: '70px', sortable:false},
        { text: '재통화 N차',   value: 'NTFCTN_Nth_03',   align: 'center', width: '70px', sortable:false},
        { text: '통화거부 1차', value: 'NTFCTN_1st_06',   align: 'center', width: '70px', sortable:false},
        { text: '통화거부 N차', value: 'NTFCTN_Nth_06',   align: 'center', width: '70px', sortable:false},
        { text: '결번 1차',     value: 'NTFCTN_1st_07',   align: 'center', width: '70px', sortable:false},
        { text: '결번 N차',     value: 'NTFCTN_Nth_07',   align: 'center', width: '70px', sortable:false},
        { text: '통보제외 1차', value: 'NTFCTN_1st_12',   align: 'center', width: '70px', sortable:false},
        { text: '통보제외 N차', value: 'NTFCTN_Nth_12',   align: 'center', width: '70px', sortable:false},
        { text: '기타 1차',     value: 'NTFCTN_1st_09',   align: 'center', width: '70px', sortable:false},
        { text: '기타 N차',     value: 'NTFCTN_Nth_09',   align: 'center', width: '70px', sortable:false},
      ],

      items: [],

      dataTableOptions01: {
        fixedHeader: true,
        hideDefaultHeader: true,
        hideDefaultFooter: true,
        items: undefined,
        itemKey: 'index',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        disablePagination: true,
        invisiblePagination: true,
      }, 

      // 신청유무결과
      headers2: [
        { text: '등록일자',   value: 'REG_DTTM',    align: 'center', width: '150px', sortable:false},
        { text: '유형',       value: 'OB_TYPE_NM',  align: 'center', width: '150px', sortable:false},
        { text: 'OB명',       value: 'OB_EVNT',     align: 'center', sortable:false},
        { text: '청약(신청)', value: 'APLY_TYPE_01', align: 'center', width: '150px', sortable:false},
        { text: '실권',       value: 'APLY_TYPE_02', align: 'center', width: '150px', sortable:false},
        { text: '반대',       value: 'APLY_TYPE_03', align: 'center', width: '150px', sortable:false},
        { text: '찬성',       value: 'APLY_TYPE_04', align: 'center', width: '150px', sortable:false},
        { text: '미결정',     value: 'APLY_TYPE_05', align: 'center', width: '150px', sortable:false},
        { text: '기신청',     value: 'APLY_TYPE_06', align: 'center', width: '150px', sortable:false},
        { text: '기타',       value: 'APLY_TYPE_07', align: 'center', width: '150px', sortable:false},
      ],

      dataTableOptions02: {
        fixedHeader: true,
        hideDefaultFooter: true,
        items: undefined,
        itemKey: 'index',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        disablePagination: true,
        invisiblePagination: true,
      }, 

      alertMsg: {
        responseError: "시스템에 오류가 발생하였습니다.",
      },

    }
  },

  computed: {
    obTypeList: function() {
      return this.mixin_common_code_get(this.commonCodeItems, 'OB00');
    },
    dataTableInstance: function() {
      return this.$refs.dataTable;
    },
    dataTableDetailInstance: function() {
      return this.$refs.dataTableDetail;
    },
  },

  created() { 
    this.dataTableOptionsEvent.headers = this.headersEvent;

    this.dataTableOptions01.headers = this.headers;
    this.dataTableOptions02.headers = this.headers2;
  },

  mounted() { 
    this.initMain();
  },

  methods: {
    // 셀렉트박스 공통코드 조회
    async initMain(){
      this.fileValue = null

      let pArr = ['OB00', 'CRS000', 'DS38', 'OB02']
      this.commonCodeItems = await this.mixin_common_code_get_all(pArr)
      
      // 화면로드시 OB유형 코드 초기값 01: 유상청약
      // 화면로드시 OB유형 코드 초기값 - 빈값 (2023.01.16)
      this.selectedOBTYPE = this.OBTYPE = "";
      this.changeObType();

      this.obTypeList.unshift({ "text": "선택", "value": "" });
    },

    // 조회 버튼
    searchList () {
      if (this.OBTYPE != '') {
        this.OB_EVNT         = "";
        this.REG_DTTM        = "";
        this.selectedEventId = "";
  
        this.selectedRows                = [];
        this.dataTableOptionsEvent.items = [];
        this.dataTableOptions01.items    = [];
        this.dataTableOptions02.items    = [];
  
        this.changeObType();
        this.setDataTableParams();
        this.getGridList();
      } else {
        this.common_alert('유형을 먼저 선택해주세요.', 'error');
      }
    },

    // OB 마스터 조회
    setDataTableParams: function() {
      this.requestData = {
        headers: {},
        sendData: {},
      };

      this.requestData.headers["URL"]      = "/api/phone/ob/manage/masterOb";
      this.requestData.headers["SERVICE"]  = "phone.ob.manage";
      this.requestData.headers["METHOD"]   = "masterOb";
      this.requestData.headers["ASYNC"]    =  false;
      this.requestData.headers["TYPE"]     = "BIZ_SERVICE";

      this.requestData.sendData.FROM       = this.dates[0];                       // 기간조회 - FROM: 시작일 
      this.requestData.sendData.TO         = this.dates[1];                       // 기간조회 - TO  : 종료일

      this.requestData.sendData["REG_ID"]  = this.userInfo.USER_ID;               // 등록자ID
      this.requestData.sendData["OB_TYPE"] = this.OBTYPE;                         // OB유형 - 01: 유상청약 / 02: 반대의사 / 03: 매수청구 / 04: TM
    },

    getGridList(loadOptions) {
      this.common_postCall(this.requestData).then(response => {
        let resHeader = response.HEADER;
        let resData = response.DATA;
        // 데이터 조회 오류 시 alert
        if (resHeader.ERROR_FLAG == true) {
          this.common_alert(this.alertMsg.responseError, "error");
          return false;
        }

        _.each(resData, (item) => {
          item.REG_DTTM = item.REG_DTTM.substr(0, 10);    // 등록일자: 날짜까지
        });

        // 순번 세팅
        this.dataTableOptionsEvent.items = resData.map(
          (item, index) => ({
          ...item,
          index: index + 1
        }));
      });
    },

    // 통계결과 조회
    statsOB(item) {
      var requestData = {
        headers: {},
        sendData: {},
      };

      requestData.headers["URL"]      = "/api/phone/ob/manage/statsOb";
      requestData.headers["SERVICE"]  = "phone.ob.manage";
      requestData.headers["METHOD"]   = "statsOb";
      requestData.headers["ASYNC"]    =  false;
      requestData.headers["TYPE"]     = "BIZ_SERVICE";

      requestData.sendData["ID"]      = item.ID  

      this.common_postCall(requestData).then(response => {
        let resHeader = response.HEADER;
        let resData = response.DATA;
        // 데이터 조회 오류 시 alert
        if (resHeader.ERROR_FLAG == true) {
          this.common_alert(this.alertMsg.responseError, "error");
          return false;
        }

        _.each(resData, (item) => {
          item.REG_DTTM = item.REG_DTTM.substr(0, 10);                    // 등록일자: 날짜까지
        });

        this.dataTableOptions01.items = resData.map(
          (item, index) => ({
          ...item,
          index: index + 1
        }));

        // OBTYPE: 04 - TM인 경우에는 신청유무결과 데이터 보여주지 않는다.
        if(this.OBTYPE != "04"){
          this.dataTableOptions02.items = resData.map(
            (item, index) => ({
            ...item,
            index: index + 1
          }));
        }
      });
    },

    // 엑셀다운로드
    excelDownload(table_id, file_name, sheet_name) {
      this.table_id   = table_id
      this.file_name  = file_name
      this.sheet_name = sheet_name
      this.multiexcelDownload();
    },

    multiexcelDownload() {
      const headers = ["통보결과", "신청유무결과"];

      function create_gap_rows (ws, nrows){
        var ref = XLSX.utils.decode_range(ws["!ref"]);
        ref.e.r += nrows;
        ws["!ref"] = XLSX.utils.encode_range(ref);
      }

      const ws = XLSX.utils.aoa_to_sheet([[headers[0]]])
      XLSX.utils.sheet_add_dom(ws, document.getElementById('OBList05'),{origin: -1, raw: true});
      create_gap_rows(ws, 1);

      XLSX.utils.sheet_add_aoa(ws, [[headers[1]]], {origin: -1});
      XLSX.utils.sheet_add_dom(ws, document.getElementById('OBList06'),{origin: -1, raw: true});
      create_gap_rows(ws, 2);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, this.obTypeName + "OB 통계");
      XLSX.writeFile(wb, this.obTypeName + "OB 통계.xlsx")

      //엑셀 내보내기 완료 후 사용된 시트명이 있다면 초기화 한다
      if (this.sheet_name !== "") {
        this.sheet_name = "";
      }

      // TM 엑셀을 따로 사용해야 한다면 사용하기
      // function create_gap_rows (ws, nrows){
      //   var ref = XLSX.utils.decode_range(ws["!ref"]);
      //   ref.e.r += nrows;
      //   ws["!ref"] = XLSX.utils.encode_range(ref);
      // }

      // const headers = ["통보결과", "신청유무결과"];

      // if(this.OBTYPE == "04"){
      //   const ws = XLSX.utils.aoa_to_sheet([[headers[0]]])
      //   XLSX.utils.sheet_add_dom(ws, document.getElementById('OBList05'),{origin: -1, raw: true});
      //   create_gap_rows(ws, 1);

      //   const wb = XLSX.utils.book_new();
      //   XLSX.utils.book_append_sheet(wb, ws, this.obTypeName + "OB 통계");
      //   XLSX.writeFile(wb, this.obTypeName + "OB 통계.xlsx")

      // } else {
      //   const ws = XLSX.utils.aoa_to_sheet([[headers[0]]])
      //   XLSX.utils.sheet_add_dom(ws, document.getElementById('OBList05'),{origin: -1, raw: true});
      //   create_gap_rows(ws, 1);

      //   XLSX.utils.sheet_add_aoa(ws, [[headers[1]]], {origin: -1});
      //   XLSX.utils.sheet_add_dom(ws, document.getElementById('OBList06'),{origin: -1, raw: true});
      //   create_gap_rows(ws, 2);

      //   const wb = XLSX.utils.book_new();
      //   XLSX.utils.book_append_sheet(wb, ws, this.obTypeName + "OB 통계");
      //   XLSX.writeFile(wb, this.obTypeName + "OB 통계.xlsx")
      // }
      // //엑셀 내보내기 완료 후 사용된 시트명이 있다면 초기화 한다
      // if (this.sheet_name !== "") {
      //   this.sheet_name = "";
      // }
    },

    fromOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
        // 종료일자보다 이후를 입력했으면 종료일자로 변경
        if (moment(this.from).isAfter(this.dates[1])) {
          this.$nextTick(() => {
            this.from = this.dates[1];
          });
        }

        this.$nextTick(() => {
          this.dates[0] = this.from;
          // datepick 다시 랜더링
          this.menuStartDate = false;
          this.menuStartDate = true;
        });
      }
    },
    changeDate(type) {
      if(type == 'start') {
        if (moment(this.dates[0]).isBefore(moment(this.dates[1]).subtract(365, 'days').format('YYYY-MM-DD'))) {
            this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
            const date = moment().subtract(30, "days").format("YYYY-MM-DD");
            this.dates[0] = date;
            this.from = date;
            return;
        } else {
            this.from = this.dates[0];
        }
      } else if (type == 'end') {
        if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(365, 'days').format('YYYY-MM-DD'))) {
            this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
            const date = moment().format("YYYY-MM-DD");
            this.dates[1] = date;
            this.to = date;
            return;
        } else {
            this.to = this.dates[1];
        }
      }
    },
    startDate(e) {
      this.from = e;
    },
    toOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
        // 시작일자보다 이전을 입력했으면 시작일자로 변경
        if (moment(this.to).isBefore(this.dates[0])) {
          this.$nextTick(() => {
            this.to = this.dates[0];
          });
        }

        this.$nextTick(() => {
          this.dates[1] = this.to;
          // datepick 다시 랜더링
          this.menuEndDate = false;
          this.menuEndDate = true;
        });
      }
    },
    endDate(e) {
      this.to = e;
    },

    // 마스터 리스트 클릭
    onClickRowEvent: function (item, row) { 
      if(this.selectedEventId === item.ID){
        row.select(false);

        this.selectedEventId = "";
        this.selectedRows = [];
        this.selectedId = "";
        this.REG_DTTM   = "";
        this.OB_EVNT    = "";

        this.dataTableOptions01.items = [];
        this.dataTableOptions02.items = [];
      }
      else
      {
        row.select(true);

        this.selectedEventId = item.ID;
        
        this.REG_DTTM = item.REG_DTTM.substr(0, 10),      
        this.OB_EVNT  = item.OB_EVNT;  

        // 통계결과 조회
        this.statsOB(item)
      }
    },

    onClickRow: function (item, row) { 
      if(this.selectedId === item.index){
        row.select(false);
        this.selectedId = "";
      }
      else
      {
        row.select(true);
        this.selectedEventId = item.index;
      }
    },

    // OBTYPE 유형의 선택된 코드로 유형코드의 text 구하기
    changeObType: function() {
      for (let i = 0; i < this.obTypeList.length; i++) {
        let obType = this.obTypeList[i];
        if (obType.value == this.OBTYPE) {
          this.obTypeName = obType.text;
        }
      }
    }
  },

};
</script>
  
<style></style>  